<template>
  <div class="filter">
    <el-select
      :popper-append-to-body="false"
      class="filter-nav"
      placeholder="请选择"
      v-model="value"
      @change="onChange"
    >
      <el-option v-for="item in list" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>
  </div>
</template>
<script>
import { listOrgData } from '@/api/property'

export default {
  components: {},
  data() {
    return {
      typeVisible: false,
      list: [],
      orgName: '请选择机构名称',
      org: {},
      searchVal: '',
      pageNo: 0,
      isEnd: false,
      value: ''
    }
  },
  mounted() {
    this.loadConfig()
  },
  methods: {
    async loadConfig() {
      try {
        const { success, result } = await listOrgData()
        if (success) {
          this.list = [
            {
              id: '',
              name: '全部'
            },
            ...result
          ]
        }
      } catch (error) {
      } finally {
      }
    },
    onSelectType() {
      console.log(this.value)
      // this.orgName = item.name
      // this.org = item
      // this.onChange()
    },
    onChange() {
      const up = {}
      up['orgId'] = this.value
      this.$emit('change', up)
    }
  }
}
</script>
<style lang="less" scoped>
.filter {
  height: 44px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  display: flex;
  align-content: center;
  justify-content: center;

  &-nav {
    background: rgba(255, 255, 255, 1);
    width: 370px;
    height: 44px;
    border: none;
  }

  //修改的是el-input的样式
  //一种方法是设置最里层el-input__inner的背景色 外层的两级父元素设置为透明色
  //另一种方法是从el-select到el-input__inenr的背景色都设置为需要的颜色
  /deep/ .el-select,
  /deep/ .el-input,
  /deep/ .el-input__inner {
    border: 0px;
    border-radius: 0px;
    height: 44px !important;
  }

  //el-input聚焦的时候 外层的border会有一个样式
  /deep/ .el-select .el-input.is-focus .el-input__inner {
    border: 0px;
  }

  //修改总体选项的样式 最外层
  /deep/ .el-select-dropdown {
    border: 0px;
    border-radius: 0px;
    min-width: 375px;
    width: 375px;
    max-width: 375px;
  }
}
</style>
