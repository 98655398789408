import { axios } from '@/utils/request'
import { getCookie } from '@/utils/domUtil'
import { ACCESS_TOKEN } from '@/store/mutation-types'

/**
 * 企业服务相关接口
 *
 *
 *
 */

// 报修列表
export function listRepairData(parameter) {
  return axios({
    url: '/repair/tbRepair/list',
    method: 'get',
    params: parameter
  })
}

// 报修列表
export function getRepairData(parameter) {
  const token = getCookie(ACCESS_TOKEN)
  return axios({
    headers: { 'X-Access-Token': token },
    url: '/repair/tbRepair/queryById',
    method: 'get',
    params: parameter
  })
}

export function upload(fileData) {
  const token = getCookie(ACCESS_TOKEN)
  return axios({
    headers: { 'X-Access-Token': token },
    method: 'post',
    url: '/sys/common/upload',
    data: fileData
  })
}

// 添加
export function editRepairData(data) {
  const token = getCookie(ACCESS_TOKEN)
  return axios({
    headers: { 'X-Access-Token': token },
    url: '/repair/tbRepair/audit',
    method: 'post',
    data
  })
}

// 获取机构列表
export function listOrgData(params) {
  return axios({
    url: '/business/tbOrg/listAll',
    method: 'get',
    params
  })
}

// 费用查询
export function listCostData(parameter) {
  return axios({
    url: '/property/tbProperty/list',
    method: 'get',
    params: parameter
  })
}

export function listHydropowerData(parameter) {
  return axios({
    url: '/property/tbProperty/queryPageListHydropower',
    method: 'get',
    params: parameter
  })
}

export function delRepairData(parameter) {
  const token = getCookie(ACCESS_TOKEN)
  return axios({
    headers: { 'X-Access-Token': token },
    url: '/repair/tbRepair/delete',
    method: 'delete',
    params: parameter
  })
}

export function listDictData(params) {
  return axios({
    url: '/sys/api/queryEnableDictItemsByCode',
    method: 'get',
    params
  })
}
